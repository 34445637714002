@tailwind base;
@tailwind components;
@tailwind utilities;



#results {
    display: none;
}

#searchbox:focus + div {
    display: block;
}